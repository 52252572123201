import React from "react"
import {Link} from "gatsby";
import {Fade} from "react-reveal";
import Img from "gatsby-image";
import styles from "./featuredBlog.module.scss"

export default function FeaturedBlog({articles, showDesc = false}) {
    return (
        <>
            {articles.map((item) => (
                <div className="mb-4 hover-animate col-sm-6 col-lg-4" key={item.slug}>
                    <Fade>
                        <div className={`border-0 h-100 shadow card ${styles.blogCard}`}>
                            <Link to={`/${item.slug}`}>
                                <div className="hovereffect">
                                    {item.featuredImage &&
                                        <Img className={styles.blogCardImage}
                                             fluid={item.featuredImage.node.remoteFile.childImageSharp.fluid}
                                             alt={item.title}/>
                                    }
                                </div>
                            </Link>
                            <div className={`p-4`}>
                                <div>{item.date}</div>
                                <Link className={styles.heading} to={`/${item.slug}`}>
                                    <h5>{item.title}</h5>
                                </Link>
                                {showDesc && <div
                                    dangerouslySetInnerHTML={{__html: item.excerpt}}
                                />}
                            </div>
                        </div>
                    </Fade>
                </div>
            ))}
        </>
    );
}
